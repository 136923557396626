import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import clsx from "clsx";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { MainJumbotron } from "../../components/jumbotron";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { screenWidth } from "../../hooks/useCssMediaDevice";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      maxWidth: 1024,
      marginTop: 220,
      margin: "auto",
      padding: "0 40px",
      fontFamily: ["Poppins", "system-ui", "sans-serif"].join(","),
      [theme.breakpoints.down(screenWidth.mobile)]: {
        marginTop: 110,
        padding: "0 24px",
      },
    },
    wrapper: {
      marginTop: 100,
      marginBottom: 100,
    },
    title: {
      fontSize: 44,
      fontWeight: 700,
      lineHeight: 1.5,
      [theme.breakpoints.down(screenWidth.mobile)]: {
        fontSize: 20,
      },
    },
    content: {
      marginTop: 24,
    },
    typo: {
      marginBottom: 0,
      fontFamily: ["Pretendard", "system-ui", "sans-serif"].join(","),
      fontSize: 20,
      lineHeight: 2,
      color: "#666666",
      [theme.breakpoints.down(screenWidth.mobile)]: {
        fontSize: 16,
      },
    },
  })
);

interface MilestonePageProps {
  pageContext: {
    language: string;
  };
}

type MilestoneItem = {
  intlId: string;
};

type MilestoneContents = {
  [year: number]: MilestoneItem[];
};

const MILESTONE_CONTENTS: MilestoneContents = {
  2023: [
    { intlId: "milestone_item_2023_1" },
    { intlId: "milestone_item_2023_2" },
  ],
  2022: [
    { intlId: "milestone_item_2022_1" },
    { intlId: "milestone_item_2022_2" },
    { intlId: "milestone_item_2022_3" },
    { intlId: "milestone_item_2022_4" },
    { intlId: "milestone_item_2022_5" },
    { intlId: "milestone_item_2022_6" },
    { intlId: "milestone_item_2022_7" },
  ],
  2021: [
    { intlId: "milestone_item_2021_1" },
    { intlId: "milestone_item_2021_2" },
    { intlId: "milestone_item_2021_3" },
    { intlId: "milestone_item_2021_4" },
    { intlId: "milestone_item_2021_5" },
    { intlId: "milestone_item_2021_6" },
  ],
  2020: [
    { intlId: "milestone_item_2020_1" },
    { intlId: "milestone_item_2020_2" },
    { intlId: "milestone_item_2020_3" },
    { intlId: "milestone_item_2020_4" },
    { intlId: "milestone_item_2020_5" },
  ],
  2019: [
    { intlId: "milestone_item_2019_1" },
    { intlId: "milestone_item_2019_2" },
    { intlId: "milestone_item_2019_3" },
    { intlId: "milestone_item_2019_4" },
    { intlId: "milestone_item_2019_5" },
    { intlId: "milestone_item_2019_6" },
  ],
  2018: [
    { intlId: "milestone_item_2018_1" },
    { intlId: "milestone_item_2018_2" },
    { intlId: "milestone_item_2018_3" },
    { intlId: "milestone_item_2018_4" },
  ],
  2017: [
    { intlId: "milestone_item_2017_1" },
    { intlId: "milestone_item_2017_2" },
  ],
  2016: [
    { intlId: "milestone_item_2016_1" },
    { intlId: "milestone_item_2016_2" },
  ],
  2015: [
    { intlId: "milestone_item_2015_1" },
    { intlId: "milestone_item_2015_2" },
    { intlId: "milestone_item_2015_3" },
  ],
};

function MilestonePage({ pageContext }: MilestonePageProps) {
  const classes = useStyles();
  const intl = useIntl();
  const { language } = pageContext;

  return (
    <Layout language={language}>
      <SEO title="History" />
      <MainJumbotron />
      <section className={classes.container}>
        <div className={classes.wrapper}>
          {Object.keys(MILESTONE_CONTENTS)
            .reverse()
            .map(year => (
              <div className={classes.wrapper}>
                <div className={classes.title}>{year}</div>
                <ul className={classes.content}>
                  {MILESTONE_CONTENTS[Number(year)].map(({ intlId }) => (
                    <li className={classes.typo}>
                      {intl.formatMessage({ id: intlId })}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </div>
      </section>
    </Layout>
  );
}

export default MilestonePage;
